// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "D_vT d_gv d_cs";
export var heroHeaderCenter = "D_gw d_gw d_cs d_dw";
export var heroHeaderRight = "D_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "D_vV d_gr d_cw";
export var heroParagraphCenter = "D_gs d_gs d_cw d_dw";
export var heroParagraphRight = "D_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "D_vW d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "D_vX d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "D_vY d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "D_vZ d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "D_v0 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "D_vn z_vn";
export var heroExceptionNormal = "D_vp z_vp";
export var heroExceptionLarge = "D_vq z_vq";
export var Title1Small = "D_t3 z_t3 z_ty z_tz";
export var Title1Normal = "D_t4 z_t4 z_ty z_tB";
export var Title1Large = "D_t5 z_t5 z_ty z_tC";
export var BodySmall = "D_vh z_vh z_ty z_tS";
export var BodyNormal = "D_vj z_vj z_ty z_tT";
export var BodyLarge = "D_vk z_vk z_ty z_tV";