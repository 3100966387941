// extracted by mini-css-extract-plugin
export var campaignContainer = "v_ss d_dW";
export var campaignContainerFull = "v_st d_dT";
export var wrapper = "v_ry d_w";
export var leftWrapper = "v_sv d_bz d_bN";
export var rightWrapper = "v_sw";
export var subtitle = "v_sx";
export var title = "v_sy";
export var text = "v_pc d_cs";
export var inlineWrapper = "v_sz d_bC d_bG d_w";
export var btnsWrapper = "v_sB v_sz d_bC d_bG d_w d_bJ";
export var priceWrapper = "v_sC v_sz d_bC d_bG d_w d_ch d_bP";
export var priceHeading = "v_sD";
export var disclaimerWrapper = "v_sF d_cD";
export var mediaSlot = "v_sG";
export var listWrapper = "v_sH d_bC d_bP d_w";
export var btnWrapper = "v_d2";
export var imageWrapper = "v_rj d_fg d_Z";
export var SmallSmall = "v_sJ z_sJ z_ty z_tW";
export var SmallNormal = "v_sK z_sK z_ty z_tX";
export var SmallLarge = "v_sL z_sL z_ty z_tT";
export var textLeft = "v_dv";
export var textCenter = "v_dw";
export var textRight = "v_dx";
export var alignLeft = "v_rd";
export var alignCenter = "v_bP";
export var alignRight = "v_rf";